import React, { useState, useEffect } from 'react';
import { UserSettingsButton } from './UserSettingsButton';
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline';

export const Header: React.FC = () => {
  const [theme, setTheme] = useState<'light' | 'dark'>(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? (savedTheme as 'light' | 'dark') : 'light';
  });

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  return (
    <header
      className="bg-white dark:bg-gray-900 shadow-lg dark:shadow-xl sticky top-4 mx-6 mt-4 p-4 flex justify-end items-center z-10 rounded-lg"
      style={{ paddingLeft: '24px', paddingRight: '24px' }}
    >
      <button
        onClick={toggleTheme}
        className="text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 p-2 rounded-full transition-all duration-300"
      >
        {theme === 'light' ? (
          <MoonIcon
            className="h-6 w-6 transition-opacity transform duration-300 ease-in-out opacity-100 scale-100"
            style={{
              opacity: theme === 'light' ? 1 : 0,
              transform: theme === 'light' ? 'scale(1)' : 'scale(0.75)',
            }}
          />
        ) : (
            <SunIcon
              className="h-6 w-6 transition-opacity transform duration-300 ease-in-out opacity-100 scale-100"
              style={{
                opacity: theme === 'dark' ? 1 : 0,
                transform: theme === 'dark' ? 'scale(1)' : 'scale(0.75)',
              }}
            />
        )}
      </button>
      <UserSettingsButton />
    </header>
  );
};
