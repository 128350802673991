import React from 'react';
import { useTranslation } from 'react-i18next';

export const UserManagement: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold">{t('admin.userManagement.title')}</h1>
      <p>{t('admin.userManagement.body')}</p>
    </div>
  );
};
