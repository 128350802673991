import { ReactNode, forwardRef } from 'react';
import cs from 'classnames';

export type ButtonProps = Omit<JSX.IntrinsicElements['button'], 'children'> & {
  children: ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  size?: 'S' | 'M';
  cancel?: boolean;
  confirm?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      leftIcon,
      rightIcon,
      onClick,
      className,
      children,
      type = 'button',
      size = 'S',
      cancel = false,
      confirm = false,
      ...restProps
    },
    ref
  ) {
    return (
      <button
        {...restProps}
        ref={ref}
        onClick={onClick}
        type={type}
        className={cs(
          'inline-flex items-center justify-between rounded-md transition-colors duration-200 ease-out',
          {
            'py-1 px-2': size === 'S',
            'py-2 px-4': size === 'M',
            'bg-white text-blue-500 border border-blue-500 hover:bg-gray-100 dark:bg-gray-800 dark:text-gray-100 dark:border-gray-700 dark:hover:bg-gray-700':
              cancel,
            'bg-blue-500 text-white border-none hover:bg-blue-400 dark:hover:bg-blue-600':
              confirm,
            'hover:bg-gray-100 dark:hover:bg-gray-700 focus:bg-gray-50 dark:focus:bg-gray-700':
              !cancel && !confirm,
          },
          className
        )}
      >
        {leftIcon && (
          <span className="mr-2" aria-hidden>
            {leftIcon}
          </span>
        )}
        <span className="flex-1">{children}</span>
        {rightIcon && (
          <span className="ml-2" aria-hidden>
            {rightIcon}
          </span>
        )}
      </button>
    );
  }
);
