import React from 'react';
import { AuthKitProvider } from '@workos-inc/authkit-react';
import CustomRouter from './router';
import config from './config';

function App() {
  return (
    <AuthKitProvider
      clientId={config.get(config.vars.workOs.clientId) || ''}
      redirectUri={config.get(config.vars.workOs.redirectUri) || ''}
    >
      <CustomRouter />
    </AuthKitProvider>
  );
}

export default App;
